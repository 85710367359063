
<template>
  <div  class="tableWrapper">
  <table  class="table table-hover text-muted">
  <thead>
  <tr>    
  </tr>
    <tr>
    
      <th>Rankings</th>
      <th>Name</th>
      <th>Gender</th>
      <th>Ratings</th>
    </tr>
  </thead>
  <tbody v-if="Players && Players.length">
    <tr v-for="(Player,Index) of Players" v-bind:key="Player.utrId">
     <template v-if="isMobileView()">
            <td>{{ incrementIndex(Index)}}</td>
            <td class="link-dark"><a :href=" Player.playerProfileUrl" target="_blank" style="color:black" >{{ Player.firstName.substr(0,1) }} {{ Player.lastName }} </a></td>
            <td>{{ Player.gender }} </td>
            <td v-if ="Player.singlesUtr != 0.00">{{ Player.singlesUtr }}</td>
            <td v-else>{{ Player.doublesUtr }}</td>
       </template>
       <template v-else>
            <td>{{ incrementIndex(Index)}}</td>
            <td class="link-dark"><a :href=" Player.playerProfileUrl" target="_blank" style="color:black">{{ Player.firstName }} {{ Player.lastName }}</a></td>
            <td>{{ Player.gender }}</td>
             <td v-if ="Player.singlesUtr != 0.00">{{ Player.singlesUtr }}</td>
            <td v-else>{{ Player.doublesUtr }}</td>
       </template>
    </tr>
  </tbody>
  <tbody v-else>
   <tr v-for="load in 64" v-bind:key="load">
        <td class="loading">
          <div class="bar"></div>
        </td>
        <td class="loading">
          <div class="bar"></div>
        </td>
        <td class="loading">
          <div class="bar"></div>
        </td>
         <td class="loading">
          <div class="bar"></div>
        </td>
    </tr>
  </tbody>
</table>

  </div>

</template>

<script>
import axios from 'axios';
import { isMobile } from 'mobile-device-detect';
export default {
  name: "App",
  data() {
    return {
      Players: {}
    }
  },
  beforeMount(){
    this.getPlayerDetails();
  },
  methods: {
    async getPlayerDetails(){
      
          let config = {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          dataType: 'jsonp',
          headers: {
              "Accept":"*/*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "authToken":"65bbaf07-db3c-4483-9667-6982b77313e3"
                }
              }
          const response = await axios.get("/v1/player/all/ratings?start=20&count=64",config)
          this.Players = response.data.data;
    },
     incrementIndex(key) {
           return key + 1;
    },
      isMobileView(){
            console.log(isMobile);
            return isMobile;
    },

  }
};
</script>