







import { Component, Vue } from 'vue-property-decorator';
import Players from './components/Players.vue';

@Component({
  components: {
    Players,
  },
})
export default class App extends Vue {}
